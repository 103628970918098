@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
.page-wrapper > div:first-child {
  display: grid;
  grid-template-rows: auto 1fr;
  overflow: hidden;
  padding-bottom: 70px;
  height: 100vh;
}

@supports (-webkit-touch-callout: none) {
  /* CSS specific to iOS devices */
  .page-wrapper > div:first-child {
    height: -webkit-fill-available;
    padding-bottom: 45px;
  }
}

/*.page-wrapper > div:first-child > div:nth-child(2) {
  overflow: auto;
}*/

